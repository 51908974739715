@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

body {
  margin: 0;
  font-family:'Electrolize', sans-serif;
  @apply bg-gray-50;
}


.ant-btn-primary {
  background-color:#1677ff;
}

.menu-link .anticon{
  display: flex;
  justify-content: center;
}


@media (min-width: 768px){
  body{
    overflow: hidden;
  }
  
  .categories::-webkit-scrollbar {
    display: none;  
  }

}

